.nav{
    width: 80%;
    align-self: center;
    font-size: 30px;
    margin-bottom: 70px;
    
    img {
        width: 50px;
    }
}
