.socials {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  align-items: center;

  .learn-more {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 30px;
    color: rgb(116, 192, 252);

    svg {
      margin-left: 10px;
    }
  }

  .social-icon {
    display: flex;
    flex-wrap: wrap;
    font-size: 25px;

    a {
      margin-right: 20px;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: #74c0fc;
      }
    }
  }
}

@media (max-width: 767px) {
  .socials {
    flex-direction: column;
    align-items: start;

    .social-icon {
      margin-top: 15px;
    }
  }
}

@media (max-width: 365px) {
  .socials {
    .learn-more {
      font-size: 16px;
    }

    .social-icon {
      font-size: 16px;
    }
  }
}
