.App {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}

.content {
  width: 65%;
  align-self: center;
  justify-self: center;
}

.App ::selection {
  background: #1f4591;
}

@media (max-width: 800px) {
  .content {
    width: 80%;
  }
}

@media (max-width: 365px) {
  .content {
    width: 85%;
  }
}
